import React from 'react'
import "../components/payments.css"

const InstallApp = () => {

    async function copyToClipboard(text) {
        try {
            await navigator.clipboard.writeText(text);
            /* ✅ Copied successfully */
        } catch (e) {
            /* ❌ Failed to copy (insufficient permissions) */
        }
    }

    const writehora = () => {
        return (<>
            <div className='contenthora'>
                <h5>🕘 Horario de atención 🕘</h5>
                <div className='contentatencion'>
                    <b>👇Atención UNICAMENTE dentro del horario👇</b>
                </div>
                <div>Lunes y viernes<br />
                    9:30 a 11:30 - 17:00 a 20:00<br />
                </div>
                <div>Martes a jueves<br />
                    9:30 a 13:00 - 17:00 a 20:00<br />
                    <b>Sabados, domingos y feriados: CERRADO</b></div>
            </div>
        </>)
    }

    const writeatencion = () => {
        return (<>
            <div className='contentatencion'>
                <b>☝️Atención UNICAMENTE dentro del horario☝️</b>
            </div>
        </>)
    }


    return (<>
        {writehora()}
        {writeatencion()}
        <div>
            <h3>Descargar 📺 en cualquiera de los siguientes equipos:</h3>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                # Convertidor android<br />
                # tablets android,<br />
                # celulares android y<br />
                # emuladores Android para Windows (recomendado Nox Player)
            </div>
        </div>
        <div>
            Seguir los pasos a continuación:<br />
            <div className='contentinstall'>
                <b>1) APP OFICIAL:</b> ir al navegador web 🌐 y escribe el siguiente link, en la barra de direcciones <button className='boton' onClick={() => window.open("https://multivisionplay.com.ar/multivision.apk")}>👉 https://multivisionplay.com.ar/multivision.apk 👈</button> v6.0.6<br />
                (NO usar la barra de búsqueda) . En celu, solo presiona el link con el dedo.<br />

                <b>2)</b> Aceptar la descarga de multivision.apk<br />

                <b>3)</b> Al terminar de descargar, clic en abrir/open (para instalar) en mensaje emergente. <br />
                Si el mensaje se borra (abrir/open) muy rápido, salga del navegador y valla a las apps del equipo, buscar file manager/archivos y alli la carpeta download y buscar el archivo tv.apk <br />
                (si tiene varios archivos llamados tv con números entre paréntesis, aquel con el numero mas grande será el último bajado).<br />

                <b>4)</b> abre la app e inicia sesión<br />

                <b>5)</b> A disfrutar!<br />
            </div>
            <hr />
            <div className='contentinstall'>
                <b>1) APP de RESPALDO:</b> ir al navegador web 🌐 y escribe el siguiente link, en la barra de direcciones <button className='boton' onClick={() => window.open("http://tvwave.tv/fenix.apk")}>👉 http://tvwave.tv/fenix.apk 👈</button> v3.0.5<br />
                (NO usar la barra de búsqueda) . En celu, solo presiona el link con el dedo.<br />

                <b>2)</b> Aceptar la descarga de fenix.apk<br />

                <b>3)</b> Al terminar de descargar, clic en abrir/open (para instalar) en mensaje emergente. <br />
                Si el mensaje se borra (abrir/open) muy rápido, salga del navegador y valla a las apps del equipo, buscar file manager/archivos y alli la carpeta download y buscar el archivo tv.apk <br />
                (si tiene varios archivos llamados tv con números entre paréntesis, aquel con el numero mas grande será el último bajado).<br />

                <b>4)</b> abre la app e inicia sesión<br />

                <b>5)</b> A disfrutar!<br />
            </div>
            <hr />
            <div className='contentinstall'>Tenga a bien tener las 2 apps, por si una falla. Además, si aún la tiene, <b>desinstale mund1al 📺 d1git4l</b></div>
            <hr />
            <div className='contentnota'>
                NOTA: *No nos hacemos responsables* por cualquier inconveniente *o daño al equipo* al instalar la app. Si usted no es avezado en el tema, le solicitamos que, *por favor contacte a su asesor/técnico IT de cabacera*, para poder recibir asesoramiento en los pasos de esta guia.
            </div>
        </div>
        <br />
        <div className='contentpdf'>
            Para TELEVISORES y dispositivos <b>AndroidTV / GoogleTV / Amazon TV</b> descargue el siguiente  <button className='boton' onClick={() => window.open("https://sib-2000.com.ar/docs/newtuto.pdf")}>Documento PDF</button>
        </div>
        <br />
        {writehora()}
        {writeatencion()}
    </>)

}

export default InstallApp